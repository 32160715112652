import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Typography from "@mui/material/Typography";
import { Link } from "gatsby-plugin-react-i18next";
import Button from "@mui/material/Button";
import { Trans } from "react-i18next";
import {
    doctorProfile__container,
    doctorProfile__content,
    doctorProfile__image,
    doctorProfile__button,
} from "./doctor-profile.module.scss";
import BasicTabs from "../../../components/tabs/tabs";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SEO from "../../../components/seo"

export default function DoctorProfile({ profile }) {
    return (
        <><SEO title={profile.name}
               description={profile.specialty}
         /><section className={doctorProfile__container}>
            <Typography align="center" color="primary" variant="h6">
                {profile.name}
            </Typography>
            <Typography align="center" variant="button" component="div">
                {profile.specialty}
            </Typography>
            <div className={doctorProfile__content}>
                <GatsbyImage
                    image={profile.image.gatsbyImageData}
                    alt={profile.name}
                    className={doctorProfile__image} />
                <Link
                    className={doctorProfile__button}
                    to={"/doctors/consult/" + profile.slug}
                >
                    <Button
                        sx={{ maxWidth: "100%", width: "100%" }}
                        size="medium"
                        variant="contained"
                        align="center"
                    >
                        <ScheduleIcon sx={{ marginRight: "0.5rem" }} />
                        <Typography
                            fontSize="0.8rem"
                            variant="button"
                            color="secondary"
                        >
                            <Trans>consult-now</Trans>
                        </Typography>
                    </Button>
                </Link>
                <BasicTabs profile={profile}></BasicTabs>
            </div>
        </section></>
    );
}
