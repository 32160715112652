import * as React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import {documentToHtmlString} from "@contentful/rich-text-html-renderer";
import DoctorProfile from "../../components/doctor/doctor-profile/doctor-profile.jsx";

export const query = graphql`
    query LocalesTabs($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs({profile}) {
    const positions = JSON.parse(profile.positions.raw)
    const education = JSON.parse(profile.education.raw);
    const languages = JSON.parse(profile.languages.raw);

    const translate = useTranslation().t;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <Tab label={translate("positions")} {...a11yProps(0)} />
                    <Tab label={translate("education")} {...a11yProps(1)} />
                    <Tab label={translate("languages")} {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <div dangerouslySetInnerHTML={{__html: documentToHtmlString(positions)}}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
            <div dangerouslySetInnerHTML={{__html: documentToHtmlString(education)}}
                />
            </TabPanel>
            <TabPanel value={value} index={2}>
            <div dangerouslySetInnerHTML={{__html: documentToHtmlString(languages)}}
                />
            </TabPanel>
        </Box>
    );
}
