import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout/layout.jsx";
import DoctorProfile from "../../components/doctor/doctor-profile/doctor-profile.jsx";
import BasicTabs from "../../components/tabs/tabs.jsx";

export const query = graphql`
    query DoctorProfile($slug: String!, $language: String!) {
        allContentfulDoctorProfile(
            filter: { slug: { eq: $slug }, node_locale: { eq: $language } }
        ) {
            nodes {
                name
                specialty
                image {
                    gatsbyImageData(
                        placeholder: DOMINANT_COLOR
                        layout: FULL_WIDTH
                    )
                }
                slug
                positions {
                    raw
                }
                education {
                    raw
                }
                languages {
                    raw
                }
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default function DoctorProfileLayout(
    {
        data: {
            allContentfulDoctorProfile: { nodes },
        },
    }
) {
    return (
        <Layout>
            <DoctorProfile profile={nodes[0]} ></DoctorProfile>
        </Layout>
    );
}
